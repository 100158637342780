@import '../../styles/mixin.scss';
.showimage{
    display: flex;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 60%);
    z-index: 1000;
    .centerdiv{
        position: relative;
        max-width: 650px;
        height: auto;
        margin: auto;
        .close{
            position: absolute;
            right: 5%;
            width: 50px;
            height: 50px;
            >img{
                @include set-wh(100%, 100%);
            }
        }
        >img{
            @include set-wh(96%, auto);
        }
    }
}