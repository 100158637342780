@import '../../styles/mixin.scss';

.input_container {
  width: 100%;
  height: auto;
  margin-bottom: 12px;
}

.input_container_mb {
  margin-bottom: 0;
}

.input_title_wrap {
  @include flex-row(center, flex-start);
  margin-bottom: 5px;

  .input_title {
    @include font(15px, 400, #fff);
  }

  .input_title_required {
    @include font(16px, 400, #f00);
    height: 16px;
    margin-left: 3px;
  }

  .label_right_text {
    margin-left: auto;
    color: #476570;
    font-size: 15px;
  }
}


.input_container input {
  @include set-wh(100%, 40px);
  padding: 0 0.375rem;
  line-height: 40px;
  background: #1A1C20;
  border-radius: 2px;
  @include font(15px, 400, #fff);
}

.input_container .input_right_icon {
  @include flex-row(center, center);
  padding: 0 0.3rem;
}

.input_container input::placeholder {
  color: #454C5F;
  font-size: 12px;
}

.input_container .placeholder_center::placeholder {
  text-align: center;
}

.input_block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
}

.contain_block {
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
  overflow: hidden;
  background-color: #1A1C20;
  border-radius: 5px;
}

input.birth_item {
  width: calc(95% / 3);
}

.money_block {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  height: 100%;
  background-color: #131c20;
}

.email_input{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 76%;
  height: auto;
  overflow: hidden;
  background-color: #040509;
  border-radius: 5px;
}

.displayIcon{
  height: 100%;
}


.btn_money {
  width: 30%;
  height: 100%;
  background-color: #2f4249;
}
@keyframes open-msg-animation {
  from { height: 0; }
  to { height: 20px; }
}
@keyframes clost-msg-animation {
  from { height: 20px; }
  to { height: 0; }
}
