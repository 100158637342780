@import '../../styles/mixin.scss';

.not_data {
    @include flex-column(center, center);
    width: 100%;
    height:auto;
    margin: 10% 0% 10% 0%;
    >img{
      @include set-wh(50%, auto);
    }
  }