@import '../../styles/mixin.scss';
.frame{
  width: 100%;
  height: auto;
  min-height: 400px;
  @include flex-row(center,center);
  flex-direction:column;
  background: #595959;
  .servicebtn{
    position:absolute;
    height: 45px;
    width: 60px;
    top: 2px;
    left: 180px;
    background: #2b4ee9;
    border-radius: 10px;
    cursor: pointer;
    >img{
      padding-left: 15%;
      margin-top: 2%;
      width: auto;
      height: 40px;
    }
  }
  .chatmessages{
    margin: 2% 2% 2% 2%;
    width: 96%;
    border: 1px solid #25252a;
    height: 60vh;
    overflow-y: scroll;
    background: #ffffff;
    .bubble {
      position: relative;
      background-color: #e8f2fe;
      border-radius: 10px;
      padding: 2%;
      width: 96%;
      margin: 2%;
    }

    .bubble:after {
      content: "";
      position: absolute;
      bottom: 8px; /* 设置尖角的位置 */
      left: -10px; /* 设置尖角的位置，根据bubble宽度调整 */
      border-width: 10px; /* 尖角大小 */
      border-style: solid;
      border-color: transparent transparent #e8f2fe transparent; /* 将右边边框颜色设置为bubble的背景色 */
      border-right-width: 0; /* 移除尖角的左边边框 */
    }
    .bubble-title1 {
      font-size: 14px;
      color: #33f;
    }
    .bubble-title2 {
      font-size: 14px;
      color: rgb(255, 46, 185);
    }
    .bubble-content {
      word-wrap: break-word;
      font-size: 16px;
      color: #000;
    }
    .bubble-image{
      height: auto;
      max-width: 90%;
    }
  }
  .chatinput{
    display: flex;
    width: 96%;
    height: auto;
    margin-bottom: 2%;
    >textarea{
      width: 80%;
      padding: 10px;
      height: 100px;
      margin-right: 5px;
      @include font(16px, bold, #222222);
      border-radius: 5px;
      background: #fff;
      word-wrap: break-word;
    }
    .rightbutton{
      width: 16%;
      height: 100px;
      .imgbutton{
        width: 100%;
        margin: 0 5px;
        height: 40px;
        border: none;
        background: #298000;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        .txtdiv{
          position: absolute;
          width: 15%;
          height: 40px;
          line-height: 40px;
          text-align: center;
          @include font(16px, bold, #222222);
        }
        .uidiv{
          position: absolute;
          width: 15%;
          height: 40px;
          >input{
            width: 100%;
            height: 100%;
            opacity: 0;
          }
          >img{
            width: 40px;
            height: 40px;
            margin-left: 25%;
            transform-origin: center center;
            animation: animate-rotate 0.8s linear infinite;
          }
        }
      }
      .chatbutton{
        width: 100%;
        height: 50px;
        margin: 5px;
        @include font(16px, bold, #222222);
        background: #2e4ef2;
        border: none;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }
}