@import '../../styles/mixin.scss';

.error_text_wrap {
  width: 100%;
  @include font(12px, 400, #f00);
  @include flex-row(center, flex-start);

  >span {
    padding-left: 5px;
  }
}

.error_msg_open {
  height: 20px;
  animation: .3s openMsgAnimation;
}

.error_msg_clse {
  height: 0;
  animation: .3s closeMsgAnimation;
}
