@import '../../styles/mixin.scss';
.frame{
  width: 100%;
  height: auto;
  min-height: 300px;
  @include flex-row(center,center);
  flex-direction:column;
  .whatapptitle{
    @include font(22px, 500, #fff);
    font-weight: bold;
  }
  .qrcode{
    width: auto;
    height: auto;
    max-width: 90%;
  }
  .email{
    @include font(20px, 500, #fff);
    font-weight: bold;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}