@import '../../styles/mixin.scss';

.main {
  @include set-wh(100%, auto);
  @include flex-column(center, center);

  .block {
    @include set-wh(100%, 15px);
  }

  .block_second {
    @include set-wh(100%, 15px);
  }
}

.switch_container {
  @include flex-row(center, center);
  @include set-wh(100%, 37px);
  .item {
    @include flex-row(center, center);
    @include set-wh(100px);
    background-color: #222222;
    @include font(16px, 500, #FFF);
    cursor: pointer;
  }

  .loginbtn{
    border-radius: 5px 0px 0px 5px;
  }

  .registerbtn{
    border-radius: 0px 5px 5px 0px;
  }

  .active {
    color: #FFF;
    background-color: #131844;
  }

  .fengexian{
    height:  37px;
    width: 2px;
    background-color: #000;
  }
}