@import '../../styles/mixin.scss';

.main {
  @include set-wh(100%, auto);
  @include flex-column;
  top: 5vh;
  margin: 5px 2% 10px 2%;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.interval{
  height: 10px;
}

.account{
  @include set-wh(96%, 35px);
  @include font(16px, bold, #fff);
}

.btnstype {
  @include set-wh(96%, 35px);
  position: relative;
  @include flex-row(center, center);
  @include font(16px, bold, #fff);
  font-family: "SF UI Display";
  background: linear-gradient(90deg, #376ef7, #2b65f6);
  border-radius: 3px;
  cursor: pointer;
}