@import '../../../styles/mixin.scss';
.safety_center_wrap {
    flex: 1;
    width: 96%;
    height: auto;
    .safety_center_item_wrap {
      width: 100%;
      background: #2E3035;
      border-radius: 1px;
      .safety_center_item_title {
        @include set-wh(100%, 36px);
        padding: 0 10px;
        background: #42464D;
        border-radius: 1px;
        @include flex-row(center, flex-start);
        cursor: pointer;
        .left_safety_icon {
          @include set-wh(16px, 16px);
          >img{
            @include set-wh;
          }
        }
  
        .title_text {
          flex: 1;
          margin: 0 10px;
          color: #FFF;
          font-weight: 400;
          font-size: 16px;
          font-family: AlibabaPuHuiTiR;
        }
  
        .bind_state_text {
          @include font(12px, 400, #7F88A4);
          margin-right: 4%;
          font-family: "Alibaba PuHuiTi";
        }
  
        .title_right_arrow {
          @include set-wh(12px, 12px);
          margin-top: -3px;
          margin-right: -3px;
          border-bottom: 1px solid #fff;
          border-left: 1px solid #fff;
          transform: rotate(-45deg);
          transform-origin: 25% 65%;
          transition: transform 0.1s linear;
        }
  
        .title_right_arrow_up {
          transform: rotate(135deg);
        }
      }
  
      .safety_center_content_box {
        @include set-wh(100%, auto);
        overflow: hidden;
  
        .setpwd_input_wrap {
          position: relative;
          margin: 0 auto;
          padding: 10px 0 10px;
          @include set-wh(90%, auto);
        }
  
        .setpwd_submit_btn {
          width: 50%;
          height: 35px;
          margin: 0 25% 5px 25%;
          @include font(16px, bold, #fff);
          border-radius: 5px;
          border: none;
          background: linear-gradient(90deg, #376ef7, #2b65f6);
          cursor: pointer;
        }
      }
    }
  }