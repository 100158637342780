@import '../../../styles/mixin.scss';

.login_wrap {
  position: relative;
  width: 100%;
  padding: 0px 2% 20px 2%;
}

.forget_password {
  position: relative;
  @include flex-row(flex-start, flex-end);
  margin-bottom: 20px;
  @include font(16px, 400, #FFFFFF);
  cursor: pointer;
}

.btn_start {
  @include set-wh(100%, 39px);
  position: relative;
  margin: 10px 0px 10px 0px;
  @include flex-row(center, center);
  @include font(18px, bold, #fff);
  font-family: "SF UI Display";
  background: linear-gradient(90deg, #376ef7, #2b65f6);
  border-radius: 3px;
  border: none;
  cursor: pointer;
}

.continue_with_text {
  @include font(14px, 400, #fff);
  margin: 25px 0 18px;
}

.other_sign_block {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 39px;
  margin: 10px 0px 10px 0px;
}