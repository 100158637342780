@import '../../../styles/mixin.scss';

.register_wrap {
  position: relative;
  flex: 1;
  box-sizing: border-box;
  width: 100%;
  padding: 0px 2% 20px 2%;
  overflow: auto;
}

.btn_start {
  @include set-wh(100%, 39px);
  position: relative;
  margin: 20px 0px 10px 0px;
  @include flex-row(center, center);
  @include font(15px, bold, #fff);
  font-family: "SF UI Display";
  background: linear-gradient(90deg, #376ef7, #2b65f6);
  border-radius: 3px;
  cursor: pointer;
}