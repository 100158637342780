@import '../../styles/mixin.scss';
.main {
    @include set-wh(100%, auto);
    @include flex-column;
    top: 5vh;
    margin: 5px 2% 10px 2%;
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
    .input_wrap {
      position: relative;
      margin: 0 auto;
      padding: 10px 0 10px;
      @include set-wh(90%, auto);
      .phonenumber{
        display: flex;
        padding-top: 4px;
        @include set-wh(100%, auto);
        justify-content:space-between;
        .item1{
          width: 35%;
          float: left;
        }
        .item2{
          width: 60%;
          float: right;
        }
      }
    }
    
    .submit_btn {
      width: 50%;
      height: 35px;
      margin: 0 25% 5px 25%;
      @include font(16px, bold, #fff);
      border-radius: 5px;
      border: none;
      background: linear-gradient(90deg, #376ef7, #2b65f6);
      cursor: pointer;
    }
}