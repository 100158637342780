@import '../../styles/mixin.scss';

.contentbox {
  position:relative;
  display: flex;
  @include flex-column(center, flex-start);
  @include set-wh(100%, auto);
  .Clear{
    position:absolute;
    height: 25px;
    width: 70px;
    top: -30px;
    left: 10px;
    >button{
      @include set-wh;
      @include font(16px, 500, #ffffff);
      border: none;
      border-radius: 5px;
      background: #1d45e4;
    }
  }
  .datalist {
    align-items: flex-start;
    justify-content: flex-start;
    @include set-wh(98%, auto);
    max-height: 80vh;
    min-height: 300px;
    flex-wrap: wrap;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
    .item {
      width: 100%;
      height: auto;
      background: #8d8d8d;
      padding: 2px 4px 2px 4px;
      border-radius: 10px;
      .itembox{
        width: 100%;
        height: 66px;
        background: #393939;
        border-radius: 5px;
        margin-bottom: 5px;
        justify-content:space-between;
        display: flex;
        .picture{
          height: 100%;
          width: auto;
          min-width: 66px;
          >img{
            left: 10px;
            width: 66px;
            height: 66px;
            border-radius: 5px;
          }
        }
        .details{
            height: 100%;
            width: 74%;
            padding-top: 5px;
            display:flex;
            flex-direction:row;
            .itemdiv{
              width: 37%;
              padding-left: 2px;
              flex-direction: column;
              font-size: 14px;
              .title{
                color: #fc9c00;
                >span{
                  color: #ffffff;
                }
              }
            }
        }
        
        .buttonprent{
          width: 10%;
          height: 100%;
          flex-direction: column;
          .clsbutton{
            width: 100%;
            height: 25px;
            >button{
              width: 25px;
              height: 25px;
              color: #000000;
              background: #c90b0b;
              border: none;
              float: right;
              font-size: 16px;
            }
          }
          .gobutton{
            width: 100%;
            height: 30px;
            margin-top: 11px;
            >button{
              width: auto;
               height: 100%;
               color: #ffffff;
               padding: 0 2px 0 2px;
               background: #4373ed;
               border-radius: 5px;
               border: none;
               float: right;
               font-size: 15px;
            }
          }
        }
      }
    }
  }

  .paybutton{
    width: 100%;
    height: auto;
    background: #ffffff;
  }
}