.hint_container {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
}

.hint_section {
  position: absolute;
  width: 80%;
  height: auto;
  padding: 5px;
  font-size: 12px;
  background-color: #373E4D;
  border-radius: 3px;
}

.hint_block {
  display: flex;
  align-items: center;
}

.hint_content {
  margin: 4px 5px;
  color: #eee;
}

.pic_size {
  width: 10px;
  >img{
    width: 100%;
    height: auto;
  }
}
