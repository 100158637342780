@import '../../styles/mixin.scss';

.message_main {
  @include flex-column;
  position: fixed;
  top: 10px;
  left: 0px;
  z-index: 999;
  min-height: 50px;
  opacity: 0;
  .message_container {
    @include flex-column(flex-start, center);
    @include set-wh;
  }
}

.message_body {
  @include flex-row;
  min-height: 50px;
  border-radius: 5px;

  .message_left {
    @include flex-row(center, center);
    @include set-wh(40px, 41px);
    min-height: 50px;
    background-color: #131C20;
  }

  .message_right {
    flex-wrap: wrap;
    box-sizing: border-box;
    max-width: 500px;
    min-height: 50px;
    padding: 0 10px 0 15.5px;
    background-color: #2F4249;
    @include set-wh(auto, 100%);
    @include flex-column(flex-start, center);

    .message_title {
      @include font(13px, 400, #FFFFFF);
    }

    .message {
      @include font(11px, 400, #5A808D);
    }

    .guide-msg-box {
      @include flex-row;

      .guide-message {
        margin: 0 3px;

        &:nth-child(1) {
          margin-left: 0;
          color: #5A808D;
        }

        &:nth-child(2) {
          color: #0DC04C;
        }

        &:nth-child(3) {
          color: #5A808D;
        }

        &:nth-child(4) {
          color: #F67934;
        }
      }
    }

    .login-complete-box {
      @include flex-row;

      .complete-message {
        margin: 0 3px;

        &:nth-child(1) {
          margin-left: 0;
          color: #5a808d;
        }

        &:nth-child(2) {
          color: #f67934;
        }

        &:nth-child(3) {
          color: #5a808d;
        }
      }
    }
  }
}

.progress-box {
  background-color: #2399FF;
  @include set-wh(100%, 3px);

  .progress {
    display: none;
    background-color: #43646F;
    animation: progress 4s linear forwards;
    @include set-wh;
  }
}

@keyframes progress {
  from {
    width: 100%;
  }

  to {
    width: 0;
  }
}

.show-box {
  animation: show-ani .5s ease-in forwards;
}

.hide-box {
  animation: hide-ani .1s ease-in forwards;
}

@keyframes show-ani {
  from {
    left: -650px;
    opacity: 0;
  }

  to {
    left: 0;
    opacity: 1;
  }
}

@keyframes hide-ani {
  from {
    left: 0;
    opacity: 1;
  }

  to {
    left: -650px;
    opacity: 0;
  }
}
