@import '../../../styles/mixin.scss';

.contentbox {
    position:relative;
    display: flex;
    @include flex-column(center, flex-start);
    @include set-wh(100%, auto);
    margin-top: 5px;
    margin-bottom: 5px;
    .scrollable{
        align-items: flex-start;
        justify-content: flex-start;
        @include set-wh(96%, auto);
        max-height: 70vh;
        min-height: 300px;
        overflow-y: auto;
        overflow-x: hidden;
        .item {
            width: 100%;
            height: auto;
            background: #8d8d8d;
            padding: 1% 2% 1% 2%;
            border-radius: 10px;
            .itembox{
                width: 100%;
                height: 66px;
                background: #393939;
                border-radius: 5px;
                margin-bottom: 1%;
                display: flex;
                justify-content:space-between;
                .picture{
                    height: 100%;
                    >img{
                        left: 10px;
                        width: 66px;
                        height: auto;
                        border-radius: 5px;
                    }
                }
                .details{
                    height: 100%;
                    margin-top: 10px;
                    justify-content:space-between;
                    >span{
                        @include font(20px, 500, #ffffff);
                        line-height: 50px;
                    }
                }
                .gobutton{
                    height: 100%;
                    margin: 15px 15px auto;
                    >button{
                        width: 70px;
                        height: 36px;
                        @include font(22px, 500, #ffffff);
                        background: #4373ed;
                        border-radius: 5px;
                        border: none;
                    }
                }
            }
        }
    }
    .paybutton{
        width: 96%;
        height: auto;
        background: #ffffff;
    }
}