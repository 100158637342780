@import '../../styles/mixin.scss';
.bg {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0 0 0 / 70%);
  -ms-overflow-style: none;
  scrollbar-width: none;
  z-index: 800;
}

.bg::-webkit-scrollbar {
  display: none;
}

.container {
  position: relative;
  width: 96%;
  max-width: 650px;
  height: auto;
  min-height: 300px;
  max-height: 80vh;
  background-color: #333333;
  border-radius: 5px;
  z-index: 801;
  .header_box {
    @include set-wh(100%, 5vh);
    flex-shrink: 0;
    background: #111111;
    .header_title {
      float: left;
      padding-left: 10px;
      padding-top: 5px;
      @include font(25px, 500, #fff);
      font-weight: bold;
    }
    .close_icon {
      float: right;
      margin-top: 5px;
      @include set-wh(35px, 35px);
      >img{
        @include set-wh(25px,25px);
      }
    }
  }
}