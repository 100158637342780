@import '../../styles/mixin.scss';

.verifyinput_wrap {
    width: 100%;
    height: auto;
    .right_send_btn_wrap {
        @include set-wh(150px, 36px);
        margin-left: 10px;
        background: linear-gradient(180deg, #FBC904 0%, #FF9001 100%);
        border-radius: 5px;
        @include flex-row(center, center);
        @include font(16px, 500, #7F3600);
        cursor: pointer;
    }
}