.regiter_loading_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.mask{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color:rgba(0,0,0,0.5);
  z-index: 1001;
}

.regiter_loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading_bg {
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  z-index: 1002;
}

.loading_content {
  position: absolute;
  top: 0;
  left: 0;
  width: 120px;
  height: 120px;
  transform-origin: center center;
  animation: animate-rotate 0.8s linear infinite;
  z-index: 1003;
}

@keyframes animate-rotate {
  from {transform: rotate(0deg);}
  to{transform: rotate(360deg);}
}
