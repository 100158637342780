@import '../../styles/mixin.scss';
.contentbox {
    position:relative;
    display: flex;
    @include flex-column(center, flex-start);
    @include set-wh(100%, auto);
    .scrollable{
        align-items: flex-start;
        justify-content: flex-start;
        @include set-wh(98%, auto);
        max-height: 75vh;
        min-height: 300px;
        overflow-y: auto;
        overflow-x: hidden;
    }
}

.datalist {
    width: 100%;
    flex-wrap: wrap;
    box-sizing: border-box;
    background: #a1adff;
    margin: 5px;
    border-radius: 10px;
    .orderdetails{
        width: 98%;
        height: 70px;
        margin: 0 1% 0 1%;
        border-radius: 10px;
        display: flex;
        flex-wrap: nowrap;
        justify-content:space-between;
        .left{
            height: 70px;
            .orderid{
                @include font(14px, 500, #ffffff);
                line-height: 30px;
            }
            .paymoney{
                @include font(14px, 500, #ff6200);
                line-height: 30px;
            }
        }
        .right{
            height: 70px;
            width: auto;
            float: right;
            .righttop{
                width: auto;
                height: 22px;
                display: flex;
                flex-direction: row-reverse;
                .imgdiv{
                    height: 22px;
                    width: 22px;
                    >img{
                        @include set-wh;
                    }
                }
                .status{
                    @include font(14px, 400, #ffffff);
                    margin-right: 5px;
                    line-height: 22px;
                }
            }
            .tracking{
                height: 22px;
                @include font(14px, 400, #ffffff);
                line-height: 22px;
            }
            .datetimetxt{
                @include font(12px, 400, #ffffff);
                line-height: 22px;
            }
        }
    }
    .item {
        width: 100%;
        height: auto;
        background: #8d8d8d;
        padding: 1% 1% 1% 1%;
        border-radius: 0px 0px 10px 10px;
        .itembox{
            width: 99%;
            height: 66px;
            background: #393939;
            border-radius: 5px;
            margin-bottom: 1%;
            display: flex;
            justify-content:space-between;
            .picture{
                height: 100%;
                width: auto;
                >img{
                    left: 10px;
                    width: 66px;
                    height: auto;
                    border-radius: 5px;
                }
            }
            .details{
                height: 100%;
                width: 74%;
                padding-top: 5px;
                display:flex;
                flex-direction:row;
                .itemdiv{
                  width: 37%;
                  padding-left: 2px;
                  flex-direction: column;
                  font-size: 14px;
                  .title{
                    color: #fc9c00;
                    >span{
                      color: #ffffff;
                    }
                  }
                }
            }
            .gobutton{
                height: 30px;
                width: auto;
                margin-top: 36px;
                >button{
                    width: auto;
                    padding: 0 2px 0 2px;
                    height: 30px;
                    color: #ffffff;
                    background: #4373ed;
                    border-radius: 5px;
                    border: none;
                    font-size: 15px;
                }
            }
        }
    }
}