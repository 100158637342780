@import '../../styles/mixin.scss';
.contentbox {
    position:relative;
    display: flex;
    margin: 2%;
    @include flex-column(center, flex-start);
    @include set-wh(96%, auto);
    .orderid{
        margin: 10px;
        @include font(25px, 500, #ffffff);
        line-height: 30px;
    }
    .btn_start {
        @include set-wh(100%, 39px);
        position: relative;
        margin: 8px 0 8px 0;
        @include flex-row(center, center);
        @include font(15px, bold, #fff);
        font-family: "SF UI Display";
        background: linear-gradient(90deg, #376ef7, #2b65f6);
        border-radius: 3px;
        cursor: pointer;
    }
}