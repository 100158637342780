@import '../../styles/mixin.scss';
.bg {
    position: fixed;
    display: flex;
    top: 50%;
    padding-left: 0%;
    max-width: 680px;
    align-items: center;
    width: 100%;
    height: auto;
    background-color: rgb(0 0 0 / 50%);
    z-index: 999;
  
  .container {
    width: 100%;
    min-width: 200px;
    height: auto;
    overflow: hidden;
    z-index: 1000;
    .tipsbg {
      @include flex-row(center,center);
      @include set-wh(100%, 44px);
      flex-shrink: 0;
      padding: 0 16px;
      background: linear-gradient(90deg,#111, #000, #111);
      .tipsmsg {
        flex: 1;
        @include flex-row(center,center);
        @include font(18px, 400, #fff);
      }
    }
  }
}